import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76526ce2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "index" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "links" }
const _hoisted_4 = { class: "link blank" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.groups, (g) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(g.title), 1),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(g.links, (l) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              (!l.ignoreInDashboard)
                ? (_openBlock(), _createBlock($setup["LinkButton"], {
                    class: "link",
                    key: l.title,
                    href: l.href,
                    title: l.title,
                    image: $setup.getImageURL(l.image),
                    blank: g.blank
                  }, null, 8, ["href", "title", "image", "blank"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 256)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(g.links, (l) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_4))
          }), 256))
        ])
      ], 64))
    }), 256))
  ]))
}