import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b8c220a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "app"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!

  return ($setup.checked)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_AppHeader, {
          class: "header",
          title: "Dashboard",
          "no-menu": true
        }),
        _createVNode($setup["IndexPage"])
      ]))
    : _createCommentVNode("", true)
}