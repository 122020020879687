import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ad2362e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FloatingButton = _resolveComponent("FloatingButton")!

  return (_openBlock(), _createBlock(_component_FloatingButton, {
    href: $props.href,
    target: $setup.linkTarget
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "info",
        style: _normalizeStyle($setup.bgStyle)
      }, [
        _createElementVNode("div", _hoisted_1, _toDisplayString($props.title), 1)
      ], 4)
    ]),
    _: 1
  }, 8, ["href", "target"]))
}